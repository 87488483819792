import { NgTemplateOutlet } from '@angular/common';
import { Component, ElementRef, EventEmitter, forwardRef, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { Params } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { FormatFileSizePipe } from '@bs24/core/pipes/format-file-size.pipe';
import { ToastService } from '@bs24/core/services/toast.service';
import { EnvConfig } from '@bs24/core/models/environment-config';
import { ProgressService } from '@bs24/core/services/progress.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { SvgIconDirective } from '@bs24/core/directives/svg-icon.directive';

@Component({
  selector: 'file-upload',
  templateUrl: 'file-upload.component.html',
  styleUrl: './file-upload.component.scss',
  providers: [
    FormatFileSizePipe,
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileUploadComponent),
      multi: true
    }
  ],
  standalone: true,
  imports: [TranslateModule, NgTemplateOutlet, SvgIconDirective]
})
export class FileUploadComponent implements OnDestroy, ControlValueAccessor {

  // @ViewChild('imageCanvas', { static: true })
  // imageCanvas!: { nativeElement: HTMLCanvasElement };

  @ViewChild('fileUpload', { static: true, read: ElementRef })
  fileUpload!: { nativeElement: HTMLInputElement };

  @Input()
  options!: Params;

  // @Input()
  // arguments!: Params;

  @Output()
  refresh: EventEmitter<boolean> = new EventEmitter<boolean>();

  //formData = new FormData();


  fileName = '';
  subs!: Subscription;

  isDisabled = false;

  constructor(private toast: ToastService, private formatFileSize: FormatFileSizePipe, private envConfig: EnvConfig, private progressService: ProgressService) {
  }

  writeValue(obj: any): void {
    if (obj) {
      throw new Error('Method not implemented.');
    }
  }

  propagateChange(_model: File) {
  }

  _onTouched() {
  }

  registerOnChange(fn: () => void) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  ngOnDestroy() {
    this.subs?.unsubscribe();
  }

  onFileSelected(event: Event, canvas: HTMLCanvasElement) {
    const element: HTMLInputElement = event.target as HTMLInputElement;
    const fileList: FileList = element.files as FileList;
    const file: File = fileList[0];
    const context = canvas.getContext('2d') as CanvasRenderingContext2D;

    if (file) {

      if (file.size < this.envConfig.features?.maxFileSize) {
        this.setCanvas(file, canvas, context);
        this.fileName = file.name;
        this.propagateChange(file);
      } else {
        this.toast.show('file-size-limit', { className: 'danger', translated: true, interpolation: { value: this.formatFileSize.transform(this.envConfig.features?.maxFileSize) }, delay: 5000 });
      }
    } else {
      context.clearRect(0, 0, canvas.width, canvas.height);
    }
  }

  clear(canvas: HTMLCanvasElement) {
    this.fileName = '';
    const context = canvas.getContext('2d') as CanvasRenderingContext2D;
    context.clearRect(0, 0, canvas.width, canvas.height);

    this.fileUpload.nativeElement.value = '';
    //this.formData.delete('file');
    this.refresh.emit(true);
  }


  private setCanvas(file: File, canvas: HTMLCanvasElement, context: CanvasRenderingContext2D) {

    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      const background = new Image();
      background.src = reader.result?.toString() || '';
      background.onload = () => {
        context.clearRect(0, 0, canvas.width, canvas.height);
        const scale = Math.max(canvas.width / background.width, canvas.height / background.height);
        const x = (canvas.width / 2) - (background.width / 2) * scale;
        const y = (canvas.height / 2) - (background.height / 2) * scale;
        context.drawImage(background, x, y, background.width * scale, background.height * scale);
      };
    };
  }
}
