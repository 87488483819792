<ng-template #defaultLabelTpl>
  <span [translate]="'insertPin'">Insert pin</span>
</ng-template>

<!-- <div class="row m-b-15">
<div class="col-12 text-center">
  <svg lib="solid" icon="unlock-alt"></svg>
  <span *ngIf="label else defaultLabelTpl">{{label}}</span>
</div>
</div> -->

<form class="d-flex justify-content-center flex-nowrap">
  @for (control of form?.controls; track control; let i = $index) {
    <div class="box me-25">
      <input #digit (blur)="onBlur($event, i)" (keydown)="block($event)" (keyup.backspace)="setFocus(i-1)" (mousedown)="onMouseDown(i)" (ngModelChange)="update($event, i)"
        [formControl]="getControl(control)" autocomplete="off" class="form-control text-center" maxlength="1" type="text" />
      </div>
    }
  </form>
