import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Spin } from '../models/design';


@Injectable({providedIn:'root'})
export class ProgressService {

  spinners: Record<string, BehaviorSubject<Spin>> = {};

  registerSpinner(name: string): BehaviorSubject<Spin> {
    const instance = new BehaviorSubject<Spin>({ name, loading: false });
    this.spinners[name] = instance;
    return instance;
  }

  toggleSpinner(name: 'global' | string, loading: boolean, label?: string) {
    this.spinners[name].next({ name, loading, label });
  }
}
