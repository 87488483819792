<div class="box day" [class.form-floating]="input.floating">
  <select (blur)="onBlur($event,'date')" (change)="modelChanged(date, 'date')" [(ngModel)]="date" [disabled]="isDisabled" class="form-select" id="day" minlength="1" required>
    @if (!date) {
      <option [value]="undefined">{{ 'day' | translate }}</option>
    }
    @for (d of dates; track d) {
      <option [ngValue]="d">{{ d }}</option>
    }
  </select>
  @if (input.floating) {
    <label for="day" translate>day</label>
  }
</div>
<div class="box month" [class.form-floating]="input.floating">
  <select (blur)="onBlur($event,'month')" (ngModelChange)="modelChanged($event, 'month')" [(ngModel)]="month" [disabled]="isDisabled" class="form-select" id="month" required>
    @if (!month) {
      <option [value]="undefined">{{ 'month' | translate }}</option>
    }
    @for (z of months; track z.value) {
      <option [ngValue]="z">{{ z.name }}</option>
    }
  </select>
  @if (input.floating) {
    <label for="month" translate>month</label>
  }
</div>
<div class="box year" [class.form-floating]="input.floating">
  <select (blur)="onBlur($event,'year')" (change)="modelChanged(year, 'year')" [(ngModel)]="year" [disabled]="isDisabled" class="form-select" id="year" minlength="4" required>
    @if (!year) {
      <option [value]="undefined">{{ 'year' | translate }}</option>
    }
    @for (y of years; track y) {
      <option [ngValue]="y">{{ y }}</option>
    }
  </select>
  @if (input.floating) {
    <label for="month" translate>year</label>
  }
</div>
