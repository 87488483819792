import { KeyValue } from '@angular/common';
import { Component, HostBinding, Injector, Input } from '@angular/core';
import { ControlValueAccessor, FormControlName, NgControl } from '@angular/forms';
import { ActivatedRoute, Params } from '@angular/router';
import { IInput, InputType } from '@bs24/core/models/forms';
import { Ages } from '@bs24/core/models/ages';

/**
 *  \    / /\  |_) |\ |  |  |\ | /__
 *   \/\/ /--\ | \ | \| _|_ | \| \_|
 *
 *  DUE TO THE IMPACT THIS FILE HAS ON THE PLATFORM DO NOT CHANGE ANYTHING AND REFER TO YOUR TEAM LEADER
 * */
@Component({ template: `` })
export class BaseField implements ControlValueAccessor {

  @HostBinding('class.hidden')
  isHidden!: boolean;

  @HostBinding('class')
  fieldClass!: string;

  @HostBinding('class.disabled')
  isDisabled!: boolean;

  @Input()
  values: Array<KeyValue<number, unknown>> = [];

  @Input()
  inputId!: string;

  @Input()
  input!: IInput;

  @Input()
  type!: InputType;

  @Input()
  formControlName!: string;

  @Input()
  tooltip!: string;

  model: unknown;
  control!: NgControl;
  ages = Ages;
  maxDate!: Date;
  minDate!: Date;
  placeholder = '';

  constructor(private injector: Injector, protected route: ActivatedRoute) {
  }

  registerOnChange(fn: () => void) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  writeValue(obj: unknown) {
    //if (obj != null) {
    this.model = obj;
    // }

    if (this.input.options?.addons) {
      this.input.options.addons.forEach((a: IInput) => {
        const ctrlAddon = this.control as FormControlName;
        if (ctrlAddon.path.length > 1) {
          a.values = ctrlAddon.formDirective.form.controls[ctrlAddon.path[0]].value[a.name];
        } else {
          a.values = ctrlAddon.formDirective.form.controls[a.name].value;
        }
      });
    }
  }

  propagateChange(_model: unknown) {
  }

  blur() {
    this.onTouched();
  }

  update(model: unknown) {
    this.onTouched();
    this.propagateChange(model);
    if (this.input?.onChange) {
      this.input.onChange(model as Params);
    }
  }

  updateAddon(model: unknown, name: string) {
    this.onTouched();
    this.control.control?.parent?.get(name)?.setValue(model);
  }

  onTouched(_?: () => void) {
  }

  protected _ngOnInit() {
    this.control = this.injector.get(NgControl);
    if (!this.input) {
      this.inputId = this.inputId ?? this.formControlName;
      this.input = {
        type: this.type,
        name: this.formControlName,
        label: this.formControlName
      };
    }
    this.fieldClass = `${this.input.type} ${this.input.name}`;
    this.isHidden = this.input.type === 'hidden';
    this.placeholder = this.input?.placeholder || '';

    if (this.input.options?.max) {
      this.maxDate = this._fromJSDate(this.input.options.max);
    }

    if (this.input.options?.min) {
      this.minDate = this._fromJSDate(this.input.options.min);
    }

    setTimeout(() => {
      if (this.input.autoselect && (['select', 'checkbox-list', 'tags', 'multi-select'].includes(this.input.type))) {
        if (this.model === undefined || this.model === null) {
          const selectedValue = this.input.values.find((v: { selected: boolean; }) => v.selected) || this.input.values[0];
          this.model = selectedValue.id;
          this.propagateChange(this.model);
        }
      }
    });
  }

  protected _fromJSDate(jsDate: string | Date): Date {
    /*if (!jsDate) {
      return;
    }*/

    if (typeof jsDate === 'string') {

      const couldBeDate = new Date(jsDate);

      if (isNaN(couldBeDate.getTime())) {
        jsDate = this.ages[jsDate as keyof typeof this.ages];
      } else {
        jsDate = couldBeDate;
      }
    }

    return jsDate;
  }
}
