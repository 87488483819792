<div [container]="options?.container" [dropdownClass]="options?.dropdownClass" ngbDropdown>
  <div class="form-select d-flex align-items-center cursor-pointer" ngbDropdownToggle>
    @if (selectedOption?.icon; as selectedIcon) {
      @if (iconLib) {
        <svg [size]="{w:24, h:24}" [lib]="iconLib" [icon]="selectedIcon" class="drop-icon"></svg>
      } @else {
        <img [src]="selectedIcon" [alt]="selectedOption?.name" height="24" width="24" class="drop-icon">
      }
    }
    <input [ngModel]="selectedOption?.name" [disabled]="isDisabled" [id]="formControlName" [name]="formControlName"
           (blur)="onBlur($event)" type="button" class="bg-transparent border-0 text-start" readonly>
  </div>

  <!--              @if ((input.values.length && values.length > 1) || !input.autoSelect) {-->
  <!--                <button ngbDropdownToggle>{{ input.options?.defaultLabel ? ( input.options?.defaultLabel | translate) : ('form.select' | translate) }}</button>-->
  <!--              }-->
  <div ngbDropdownMenu aria-labelledby="dropdown" class="border w-100">
    @for (option of values;track $index) {
<!--      <pre>{{ option|json }}</pre>-->
      <button [disabled]="isDisabled" (click)="update(option.id)" ngbDropdownItem type="button" class="w-100">
        @if (option.icon) {
          @if (iconLib) {
            <svg [size]="{w:24, h:24}" [lib]="iconLib" [icon]="option.icon" class="me-50 drop-icon"></svg>
          } @else {
            <img [src]="option.icon" [alt]="option.name" height="24" width="24" class="me-50 drop-icon" loading="lazy">
          }
        }
        <span>{{ option.name | slice : 0 : 50 }}</span>
        @if (option.name && option.name.length > 50) {
          <span>...</span>
        }
      </button>
    }
  </div>
</div>
