<ng-template #inputTpl>
  @switch (input.type) {
    @default {
      <input (blur)="blur()" (ngModelChange)="propagateChange($event)" [(ngModel)]="model"
             [disabled]="isDisabled" [id]="inputId"
             [name]="formControlName" [placeholder]="input.placeholder ? (input.placeholder| translate) : ''" [restrict]="input?.restrict"
             [type]="input.type" [attr.autocomplete]="input?.autocomplete" class="form-control">
    }
    @case ('calendar') {
      <div class="input-group">
        <input #t="ngbDatepicker" placement="bottom-left" (click)="t.open()" readonly
               [style]="{backgroundColor: isDisabled ? null: 'transparent'}" (ngModelChange)="propagateChange($event)"
               [(ngModel)]="model" [disabled]="isDisabled" [id]="inputId" [maxDate]="fromJSDate(input?.options?.max)"
               [minDate]="fromJSDate(input.options?.min)"
               class="form-control" [name]="formControlName" [placeholder]="input.placeholder | translate" ngbDatepicker
               placeholder="yyyy-mm-dd">
        <div class="input-group-append">
          <button (click)="t.toggle()" class="btn btn-dark rounded-right" [disabled]="isDisabled" type="button">
            <svg class="fill-white" icon="calendar-day" lib="solid"></svg>
          </button>
        </div>
      </div>
    }
    @case ('password') {
      <password (blur)="blur()" [restrict]="input?.restrict" (ngModelChange)="propagateChange($event)"
                [(ngModel)]="model" [disabled]="isDisabled" [id]="inputId"
                [options]="input.options"
                [name]="formControlName" [placeholder]="input.placeholder | translate" class="input-group"></password>
    }
    <!-- <causal-groups (blur)="blur()" (ngModelChange)="propagateChange($event)" *ngSwitchCase="'causal-groups'"
    [(ngModel)]="model" [disabled]="isDisabled" [id]="inputId"
  [name]="formControlName"></causal-groups>-->
    @case ('radio') {
      @for (option of input.values; track option) {
        <div class="form-check form-check-inline">
          <input (ngModelChange)="update($event)" [(ngModel)]="model" [disabled]="isDisabled" [id]="option.id"
                 [name]="formControlName" [value]="option.id"
                 class="form-check-input" type="radio">
          <label [for]="option.id" class="form-check-label">{{ option.name | translate }}</label>
        </div>
      }
    }
    @case ('checkbox') {
      <div class="form-check me-50">
        <input (blur)="onTouched()" (ngModelChange)="propagateChange($event)"
               [(ngModel)]="model" [disabled]="isDisabled" [id]="inputId"
               [name]="formControlName" [placeholder]="input.placeholder | translate" class="form-check-input"
               type="checkbox">
        <label [class.required]="input.validations?.requiredTrue || input.validations?.required" [for]="inputId"
               class="form-check-label" [innerHTML]="input.label | translate"></label>
      </div>
    }
    @case ('textarea') {
      <textarea (blur)="onTouched()" (ngModelChange)="propagateChange($event)"
                [(ngModel)]="model" [disabled]="isDisabled" [id]="inputId" [name]="formControlName"
                [placeholder]="input.placeholder | translate" [restrict]="input?.restrict" class="form-control"
                cols="30"
                rows="10"></textarea>
    }
    @case ('select') {
      <select (blur)="onTouched()" (ngModelChange)="propagateChange($event)" [(ngModel)]="model"
              [disabled]="isDisabled" [id]="inputId" [name]="formControlName"
              class="form-control">
        @if (input.values?.length > 1 || !input.autoselect) {
          <option
            [ngValue]="''">{{ (input.placeholder || 'all') | translate }}
          </option>
        }
        @for (option of input.values; track option) {
          <option [value]="option.id">{{ option.name }}</option>
        }
      </select>
    }
    @case ('slide-toggle') {
      <div class="form-check form-switch ">
        <input class="form-check-input" type="checkbox" [id]="inputId" [(ngModel)]="model" [disabled]="isDisabled"
               [name]="formControlName" (blur)="blur()" (ngModelChange)="propagateChange($event)">
        @if (input.label) {
          <label class="form-check-label" [for]="inputId">{{ input.label }}</label>
        }
      </div>
    }
    <!--<slide-toggle (blur)="blur()" (ngModelChange)="propagateChange($event)" *ngSwitchCase="'slide-toggle'"
    [(ngModel)]="model" [disabled]="isDisabled" [id]="inputId"
  [name]="formControlName"></slide-toggle>-->
    @case ('date-selector') {
      <date-selector (blur)="blur()" (ngModelChange)="propagateChange($event)" [(ngModel)]="model"
                     [attr.autocomplete]="input.autocomplete" [disabled]="isDisabled" [max]="maxDate" [min]="minDate"
                     [id]="inputId" [name]="formControlName" [input]="input"></date-selector>
    }
    @case ('date-range') {
      <date-range (blur)="blur()" (ngModelChange)="propagateChange($event)"
                  [minDate]="minDate" [maxDate]="maxDate" [(ngModel)]="model" [disabled]="isDisabled" [id]="inputId"
                  [name]="formControlName"></date-range>
    }
    @case ('mobile-prefix') {
      <mobile-prefix (blur)="blur()" (ngModelChange)="propagateChange($event)"
                     [(ngModel)]="model" [disabled]="isDisabled" [id]="inputId" [input]="input"
                     [name]="formControlName" [placeholder]="input.placeholder | translate"></mobile-prefix>
    }
    <!--<mobile-prefix (blur)="blur()" (ngModelChange)="propagateChange($event)" *ngSwitchCase="'phone'"
    [(ngModel)]="model" [disabled]="isDisabled" [id]="inputId"
  [name]="formControlName" [placeholder]="input.placeholder | translate"></mobile-prefix>-->
      <!--    <geo-infos [class.required]="input.validations?.required" (ngModelChange)="propagateChange($event)"
      *ngSwitchCase="'geo-infos'" [(ngModel)]="model" [options]="input.options"
    [typeKeys]="[{key:GeoInfoType.Residence, value:'residence'}]"></geo-infos>-->
      <!-- <birth-place [class.required]="input.validations?.required" (ngModelChange)="propagateChange($event)"
    *ngSwitchCase="'birth-place'" [(ngModel)]="model" [disabled]="isDisabled"></birth-place>-->
    @case ('pin') {
      <pin-input (blur)="blur()" (ngModelChange)="propagateChange($event)"
                 [(ngModel)]="model" [disabled]="isDisabled" [id]="inputId" [name]="formControlName"></pin-input>
    }
    @case ('file'){
      <file-upload (blur)="blur()" (ngModelChange)="update($event)" [options]="input.options"
                   ngModel [disabled]="isDisabled" [id]="inputId" [name]="formControlName"></file-upload>
    }
    @case ('dropdown') {
      @if ((input.options?.async ? (input.values|async) : (input.values || [])); as selectValues) {
        <dropdown [(ngModel)]="model" [disabled]="isDisabled" [id]="formControlName" [name]="formControlName" [values]="selectValues" [options]="input.options" (blur)="onTouched()" (ngModelChange)="update($event)" class="w-100"></dropdown>
      }
    }
  }
</ng-template>

<ng-template #normalTpl>
  <div [ngClass]="input.type" class="w-100 mb-2" [class.mt-2]="input.type === 'checkbox' || input.type === 'radio'">
    @if (input.type !== 'checkbox' && input.type !== 'slide-toggle' && input.label) {
      <label [for]="formControlName" [innerHTML]="input.label | translate"
             [class.required]="input.validations?.required"></label>
    }
    <div class="align-items-center d-flex">
      <ng-content select="div.pre"></ng-content>
      <ng-content select="div.input-group-prepend"></ng-content>
      <ng-template [ngTemplateOutlet]="inputTpl"></ng-template>
      <ng-content select="div.post"></ng-content>
    </div>

    <field-error [control]="control" class="col-12"></field-error>
  </div>
</ng-template>

<ng-template #floatingTpl>
  <div class="form-floating w-100 mb-2">
    <ng-template [ngTemplateOutlet]="inputTpl"></ng-template>
    @if (input.type !== 'checkbox' && input.label) {
      <label [for]="formControlName" [innerHTML]="input.label | translate"
             [class.required]="input.validations?.required"></label>
    }
    <field-error [control]="control" class="col-12"></field-error>
  </div>
</ng-template>

<ng-template [ngTemplateOutlet]="input.floating ? floatingTpl:normalTpl"></ng-template>
