import { AfterViewInit, Component, ElementRef, EventEmitter, forwardRef, Output, QueryList, ViewChildren } from '@angular/core';
import { AbstractControl, FormControl, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { BasePinInput } from '@bs24/forms/base/base-pin-input';

/**
 * The component generates us the phone pin fields
 */
@Component({
  selector: 'pin-input',
  templateUrl: 'pin-input.component.html',
  styles: [`:host {
    display: block
  }

  .box {
    height: 38px;
    width: 38px;
  }
  `],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PinInputComponent),
    multi: true,
  }],
  standalone: true,
  imports: [
    ReactiveFormsModule
  ]
})
export class PinInputComponent extends BasePinInput implements AfterViewInit {
  /**
   * outputs the blur effect flow from the child to the parent
   */
  @Output() blur = new EventEmitter<any>(null);
  /**
   * gets us the QueryList of all the inputs generated in the view DOM
   */
  @ViewChildren('digit', {read: ElementRef})
  digitsComponents: QueryList<ElementRef>;

  /**
   * function handles our blur effect of the input
   * @param event
   * @param index
   */
  onBlur(event, index: number) {
    // todo: trick for propagate blur, check if there is another way
    const elms = this.digitsComponents.toArray();
    this.indexSet.add(index);
    if ([...this.indexSet].length === elms.length) {
      this.blur.emit(event);
    }
  }

  /**
   * returns us the form control, from the form array
   * @param control
   */
  getControl(control: AbstractControl): FormControl {
    return control as FormControl;
  }

  /**
   * lifecycle hook, passes to the super class BasePinInput
   *
   * see {@link BasePinInput} for more information
   */
  ngAfterViewInit() {
    super.afterViewInit(this.digitsComponents);
  }
}
