import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseField } from '@bs24/forms/base/base-field';
import { NgbDate, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { DropdownComponent } from '../dropdown/dropdown.component';
import { PasswordComponent } from '../password/password.component';
import { TranslateModule } from '@ngx-translate/core';
import { DateSelectorComponent } from '../date-selector/date-selector.component';
import { DateRangeComponent } from '../date-range/date-range.component';
import { PinInputComponent } from '../pin-input/pin-input.component';
import { AsyncPipe, NgClass, NgTemplateOutlet } from '@angular/common';
import { FieldErrorComponent } from '../field-error/field-error.component';
import { MobilePrefixComponent } from '../mobile-prefix/mobile-prefix.component';
import { RestrictInputDirective } from '@bs24/core/directives/restrict-input.directive';
import { SvgIconDirective } from '@bs24/core/directives/svg-icon.directive';
import { FileUploadComponent } from '../file-upload/file-upload.component';

/**
 * component act as a parent cva for all the cva, so we can switch the template field by input type passed
 */
@Component({
  selector: 'field[input], field[formControlName][type], field[ngModel]',
  templateUrl: './field.component.html',
  styleUrls: ['./field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FieldComponent),
      multi: true
    }
  ],
  standalone: true,
  imports: [
    NgbInputDatepicker,
    PasswordComponent,
    FormsModule,
    TranslateModule,
    RestrictInputDirective,
    DateSelectorComponent,
    DateRangeComponent,
    PinInputComponent,
    NgClass,
    NgTemplateOutlet,
    FieldErrorComponent,
    MobilePrefixComponent,
    SvgIconDirective,
    FileUploadComponent,
    DropdownComponent,
    AsyncPipe
  ]
})
export class FieldComponent extends BaseField implements OnInit {

  /**
   * option values for the input fields, that we can pass from the json form
   */
  @Input()
  options: any;

  /**
   * calls the base class _ngOnInit, and if we have options values we set those options to the input field
   */
  ngOnInit(): void {
    super._ngOnInit();

    if (this.options) {
      this.input = Object.assign(this.input, this.input.options, {options: this.options});
    }
  }

  /**
   * function sets us the minDate and maxDate values in readable format for ngbDatepicker
   * @param jsDate
   * @protected
   */
  protected fromJSDate(jsDate: string | Date): NgbDate {

    jsDate = super._fromJSDate(jsDate);

    return new NgbDate(jsDate.getFullYear(), jsDate.getMonth() + 1, jsDate.getDate());
  }
}
