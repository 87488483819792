import { Component, HostBinding, Input } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

/**
 * component outputs us error message of a certain field that contains error
 */
@Component({
  selector: 'field-error',
  templateUrl: './field-error.component.html',
  styleUrls: ['./field-error.component.scss'],
  imports: [
    TranslateModule
  ],
  standalone: true
})
export class FieldErrorComponent {
  /**
   * the control field
   */
  @Input()
  control: AbstractControl | NgControl;

  @Input()
  server = false;

  /**
   * a getter for a control, that loops thought the error message, translate it, and it outputs them in the template
   */
  @HostBinding('class.error')
  get error() {

    if (this.control.untouched) {
      return null;
    }

    for (const propertyName in this.control.errors) {
      if (propertyName in this.control.errors) {
        return {key: propertyName, value: this.control.errors[propertyName]};
      }
    }

    return null;
  }


}
