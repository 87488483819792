<div class="input-group flex-nowrap">
  <div class="input-group-prepend" [class.form-floating]="input.floating">
    @if (model?.prefix) {
      <div class="label d-flex align-items-center">
        @if (((model.prefix.code?.length > 2 ? (model.prefix.code | slice:3:5) : model.prefix?.code) | lowercase); as code) {
          <svg [size]="{w:24, h:24}" class="flag mx-25" [icon]="code" lib="flags"></svg>
        }
        <div class="d-flex flex-column country-container">
          <div class="country text-truncate">{{ model.prefix.code }}</div>
          <div class="prefix">(+{{ model.prefix.prefix }})</div>
        </div>
      </div>
      <select class="countries form-select" aria-label="prefix" (ngModelChange)="update(model)" [(ngModel)]="model.prefix" [disabled]="isDisabled" id="mobile-prefix">
        @for (c of countries; track c) {
          <option [ngValue]="c">{{ c.name }} ({{ c.prefix }})</option>
        }
      </select>
    }
  </div>

  @if (model) {
    <div class="d-flex flex-grow-1 input-field" [class.form-floating]="input.floating">
      <input (blur)="onBlur($event)" class="form-control" [readonly]="readonly" (ngModelChange)="update({phone:$event, prefix:model.prefix})" [(ngModel)]="model.phone"
             [placeholder]="placeholder" [restrict]="['integer']" id="mobile-phone" type="tel">
    </div>
  }

</div>
