<ng-template #loadTpl>
  <div class="d-flex justify-content-center position-absolute bottom-center w-100">
    <button type="button" (click)="fileUpload.click()" class="btn btn-primary d-flex align-items-center">
      <svg class="fill-white" [size]="{ w: 12, h: 12 }" icon="hdd"></svg>
      <span class="ms-25" translate>select</span>
    </button>
  </div>
</ng-template>
<ng-template #loadedTpl>
  <div class="position-absolute bottom-center w-100 px-1 px-lg-3 mb-1">
    <div class="form-label bg-secondary mb-1 text-truncate">{{ fileName }}</div>
    <div class="d-flex justify-content-center">
      <button type="button" (click)="clear(imageCanvas)" class="btn btn-sm btn-danger d-flex align-items-center">
        <span class="me-25" translate>clear</span>
        <svg [size]="{ w: 12, h: 12 }" icon="trash"></svg>
      </button>
    </div>
  </div>
</ng-template>
<input #fileUpload [accept]="options.accept" (change)="onFileSelected($event, imageCanvas)" class="form-control d-none" type="file">
<div class="file-upload d-flex position-relative overflow-auto">
  <div class="position-absolute top-center">
    <ng-content></ng-content>
  </div>
  <canvas #imageCanvas class="" [height]="fileName ? 270 : 50"></canvas>
  <ng-template [ngTemplateOutlet]="fileName ? loadedTpl:loadTpl"></ng-template>
</div>
