import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { IMobilePrefix } from '@bs24/core/models/mobile-prefix';
import { IPrefix } from '@bs24/core/models/prefix';
import { IInput } from '@bs24/core/models/forms';
import { WebappService } from '@bs24/core/services/webapp.service';

/**
 *  \    / /\  |_) |\ |  |  |\ | /__
 *   \/\/ /--\ | \ | \| _|_ | \| \_|
 *
 *  DUE TO THE IMPACT THIS FILE HAS ON THE PLATFORM DO NOT CHANGE ANYTHING AND REFER TO YOUR TEAM LEADER
 * */
@Component({template: ``})
export class BaseMobilePrefix implements ControlValueAccessor {
  /**
   * responsible for disabling a field
   */
  isDisabled!: boolean;
  /**
   * keeps track of value and user interaction of the control and keep the view synced with the model
   *
   * see {@link IMobilePrefix} for the model information
   */
  model!: IMobilePrefix;
  /**
   * placeholder for the input field that we can set from parent component
   */
  @Input()
  placeholder: string = '';
  /**
   * the readonly input option that we can pass from parent component
   */
  @Input()
  readonly!: boolean;
  /**
   * outputs the blur effect flow from the child to the parent
   */
  @Output()
  blur = new EventEmitter<any>(undefined);
  /**
   * local reference of Array<IPrefix>
   */
  countries!: Array<IPrefix>;


  @Input()
  input!: IInput;

  /**
   * The constructor sets us the placeholder value
   * @param bs
   */
  constructor(private bs: WebappService) {
    this.placeholder = this.placeholder || '';
  }

  /**
   * emits the blur event to the parent element, and it invokes registerOnTouched method
   * @param event
   */
  onBlur(event: any) {
    this.onTouched();
    this.blur.emit(event);
  }

  /**
   * on change of prefix or mobile input value, we update the model value, and register those changes
   * @param model
   */
  update(model: IMobilePrefix) {
    /* this.model = model;
     this.onTouched();
     this.propagateChange(model);*/
    this.model = model;
    if (model.phone !== '' && model.prefix) {
      this.propagateChange(model);
    } else {
      this.propagateChange(null);
    }
  }

  /**
   * function when it has countries values, it will find and update the model values, otherwise it will fetch the prefixes updates the countries, and call method again the recursive way
   * @param model
   */
  writeValue(model: Partial<IMobilePrefix> | string): void {
    if (this.countries) {

      if (model && model !== '') {

        let phoneNumber = typeof model === 'string' ? model as string : `+${model.prefix?.prefix}${model.phone}`;

        for (let i = 1; i <= 4; i++) {
          const target = this.countries.find(x => x.prefix === phoneNumber.substring(1, i));
          if (target) {
            this.model = new IMobilePrefix(phoneNumber.substr(i), target);
            this.update({phone: phoneNumber.substr(i), prefix: target});
            break;
          } else {
            // if not matched take first as fallback
            this.model = new IMobilePrefix('', this.countries[0]);
            this.update({phone: phoneNumber.substr(i), prefix: this.countries[0]});
          }
        }
      } else { // if not defined take first as default
        this.model = new IMobilePrefix('', this.countries[0]);
      }
    } else {

      this.countries = this.bs.webappConfig().prefixes;
      /*this.catalogService.prefixes().subscribe({
        next: prefixes => {
          this.countries = prefixes;
          //this.countries.push({id: 209, prefix: "356", name: "Malta", code: "MT"})
          this.writeValue(model);
          if (this.countries.length === 1) {
            this.setDisabledState(true)
          }
        }
      });*/
    }
  }

  /**
   * Registers a callback function that is called when the control's value changes in the UI
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Registers a callback function that is called by the forms API on initialization to update the form model on blur
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * function that is called by the forms API when the control status changes to or from 'DISABLED'.
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  /**
   * we save the given function of registerOnTouched, so that our class calls it when the control should be considered blurred or "touched".
   * @private
   */
  private onTouched() {
  }

  /**
   * we save the given function from registerOnChange, so our class calls is at the appropriate time.
   * @param _model
   * @private
   */
  private propagateChange(_model: IMobilePrefix | null) {
  }

}
