<div class="input-group">
  <input (blur)="onBlur($event)" (focus)="focused=true" (keyup)="strength" (ngModelChange)="update($event)" [(ngModel)]="model" [disabled]="isDisabled"
         [placeholder]="placeholder" [type]="toggle?'text':'password'" class="form-control" autocomplete="new-password" />
  @switch (!!options.hasStrength) {
    @default {
      <div class="input-group-text">
        <button (click)="toggle = !toggle" [disabled]="isDisabled" [ngbTooltip]="options.tooltip | translate" tabindex="-1" type="button" class="btn d-flex align-items-center p-0">
          <svg class="fill-white" [icon]="toggle ? 'eye-slash' : 'eye'" lib="solid"></svg>
        </button>
      </div>
    }
    @case (true) {
      <div class="input-group-text">
        <button (click)="toggle = !toggle" [disabled]="isDisabled" [ngbTooltip]="options.tooltip | translate" tabindex="-1" type="button" class="btn d-flex align-items-center p-0">
          <svg class="fill-white" [icon]="toggle ? 'eye-slash' : 'eye'" lib="solid"></svg>
        </button>
      </div>
    }
  }
</div>
