import { Component, EventEmitter, forwardRef, HostBinding, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconDirective } from '@bs24/core/directives/svg-icon.directive';

/**
 * The component contains the password field, that shows us the strength of password, and toggles between the input type, so we can see the password as text
 */
@Component({
  selector: 'password',
  templateUrl: './password.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordComponent),
      multi: true
    }
  ],
  standalone: true,
  imports: [
    NgbTooltipModule,
    TranslateModule,
    SvgIconDirective,
    FormsModule
  ]
})
export class PasswordComponent implements ControlValueAccessor, OnInit {

  @HostBinding('class.focused')
  focused = false;
  /**
   * toggles input type field, and icon class on button click
   */
  toggle: boolean;
  /**
   * responsible for disabling the field
   */
  isDisabled: boolean;
  /**
   * keeps track of value and user interaction of the control and keep the view synced with the model
   */
  model: string;
  /**
   * the input field placeholder
   */
  @Input()
  placeholder: string;
  /**
   * option values for the input fields, that we can pass from the json form
   */
  @Input()
  options: any;
  /**
   * outputs the blur effect flow from the child to the parent
   */
  @Output() blur = new EventEmitter<any>(null);

  /**
   * The constructor initializes default values for toggle, and placeholder
   */
  constructor() {
    this.toggle = false;
    this.placeholder = this.placeholder || '';
  }

  /**
   * a getter where we check for model value, and for hasStrength true value, we add a host class strength, and return the strength by the estimations value
   */
  @HostBinding('class')
  get strength() {
    if (!this.options.hasStrength || !this.model) {
      return '';
    }

    const estimation = this.testPassword(this.model || '');

    return ['weak', 'low', 'medium', 'good', 'strong'][estimation];
  }

  /**
   * lifecycle hook, sets the options for the input field
   */
  ngOnInit() {
    this.options = Object.assign({hasStrength: false, tooltip: null}, this.options);
  }

  /**
   * on change of password values in the view, we register the value changes
   * @param values
   */
  update(model: string) {
    this.propagateChange(model);
  }

  /**
   * propagate the blur, and update the form model on blur
   * @param event
   */
  onBlur(event) {
    // todo: trick for propagate blur, check if there is another way
    this.onTouched();
    this.blur.emit(event);
    this.focused = !!this.model;
  }

  /**
   * we assign a value to the model, when programmatic changes from model to view are requested
   * @param model
   */
  writeValue(model: any): void {
    if (model !== undefined) {
      this.model = model;
    }
  }

  /**
   * Registers a callback function that is called when the control's value changes in the UI
   * @param fn
   */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Registers a callback function that is called by the forms API on initialization to update the form model on blur
   * @param fn
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * Function that is called by the forms API when the control status changes to or from 'DISABLED'
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  /**
   * we save the given function of registerOnTouched, so that our class calls it when the control should be considered blurred or "touched".
   * @private
   */
  private onTouched() {
  }

  /**
   * function tests the strength of the password, so we can apply the correct message
   * @param pwd
   * @private
   */
  private testPassword(pwd: any): number {
    const strength = [];

    let sum = -1;

    // almost 6 chars
    if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[\d!@?[\]"£$%^&*#.,{}<>()_-])[A-Za-z\d!@?[\]"£$%^&*#.,{}<>()_-]{8,20}$/.test(pwd)) {
      return sum;
    }

    // almost a minimum
    strength[0] = /[a-zA-Z\d]/.test(pwd);
    // almost a special char
    strength[1] = /[!@?[\]"£$%^&*#.,{}<>()_-]/.test(pwd);
    // almost a digit and a special character
    strength[2] = /(?=.*\d)/.test(pwd) && /(?=.*[!@?[\]"£$%^&*#.,{}<>()_-])/.test(pwd);
    // almost a digit or a special character and lenght 10
    strength[3] = /[a-zA-Z\d!@?[\]"£$%^&*#.,{}<>()_-]{10}/.test(pwd);
    // almost a 2 digits and 2 special characters and lenght > 10
    strength[4] = /(?=(.*\d){2})/.test(pwd) && /(?=(.*[!@?[\]"£$%^&*#.,{}<>()_-]){2})/.test(pwd);


    for (const s in strength) {
      if (strength[s]) {
        ++sum;
      }
    }

    return sum;
  }

  /**
   * we save the given function from registerOnChange, so our class calls is at the appropriate time.
   * @param _model
   * @private
   */
  private propagateChange(_model: string) {
  }

}
