<!--<pre>{{fromDate}}</pre>
<pre>{{toDate}}</pre>-->
<div class="row flex-nowrap g-25">
  <div class="col-6">
    <div class="input-group">
      <div class="input-group-text p-0">
        <button (click)="f.toggle()" aria-label="from date" class="btn btn-sm rounded-0 h-100 d-flex align-items-center" type="button">
          <svg class="fill-white" icon="calendar-day" lib="solid"></svg>
        </button>
      </div>
      <input #f="ngbDatepicker" [(ngModel)]="fromDate" [disabled]="isDisabled" [maxDate]="toNbgDate(toDate)" [minDate]="toNbgDate(minDate)" [ngStyle]="{backgroundColor: isDisabled ? 'transparent': undefined}" (blur)="onBlur($event)"
             (ngModelChange)="update({from:$event, to:toDate})"
             class="form-control" name="from" ngbDatepicker placeholder="yyyy-mm-dd" readonly>
    </div>
  </div>

  <div class="col-6">
    <div class="input-group">
      <div class="input-group-text p-0">
        <button (click)="t.toggle()" aria-label="to date" class="btn btn-sm rounded-0 h-100 d-flex align-items-center" type="button">
          <svg class="fill-white" icon="calendar-day" lib="solid"></svg>
        </button>
      </div>
      <input #t="ngbDatepicker" [(ngModel)]="toDate" [disabled]="isDisabled" [maxDate]="toNbgDate(maxDate)" [minDate]="toNbgDate(fromDate)" [ngStyle]="{backgroundColor: isDisabled ? 'transparent': undefined}" (blur)="onBlur($event)"
             (ngModelChange)="update({to:$event, from:fromDate})" class="form-control" name="to" ngbDatepicker placeholder="yyyy-mm-dd" readonly>
    </div>
  </div>
</div>
