import { SlicePipe } from '@angular/common';
import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { Params } from '@angular/router';
import { SvgIconDirective } from '@bs24/core/directives/svg-icon.directive';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

interface DropdownValues {
  id: string;
  name: string;
  icon?: string;
}

@Component({
  selector: 'dropdown',
  templateUrl: './dropdown.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownComponent),
      multi: true
    }
  ],
  standalone: true,
  styles: [`.dropdown-toggle::after {
    content: none
  }`],
  imports: [
    NgbDropdownModule,
    SlicePipe,
    FormsModule,
    ReactiveFormsModule,
    SvgIconDirective
  ]
})
export class DropdownComponent implements ControlValueAccessor, OnInit {


  model!: string;
  isDisabled!: boolean;

  @Input()
  formControlName!: string;

  @Input({ required: true })
  values!: Array<DropdownValues>;

  @Output()
  blur = new EventEmitter<Event>();

  /**
   * option values for the input fields, that we can pass from the json form
   */
  @Input()
  options!: Params | undefined;

  iconLib!: string;

  ngOnInit(): void {
    if (this.options?.iconLib) {
      this.iconLib = this.options?.iconLib;
    }
    // this.iconLib = this.options?.iconLib ?? 'solid';
  }

  writeValue(obj: string): void {
    if (obj) {
      this.model = obj;
    }
  }

  update(model: string) {
    this.model = model;
    this.propagateChange(model);
  }

  onBlur(event: Event) {
    this.onTouched();
    this.blur.emit(event);
  }

  registerOnChange(fn: () => void) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  get selectedOption() {
    return this.values.find(v => v.id === this.model);
  }

  private onTouched() {
  }

  private propagateChange(_model: string | null) {
  }
}
