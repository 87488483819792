import { Component, forwardRef } from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { BaseMobilePrefix } from '@bs24/forms/base/base-mobile-prefix';
import { LowerCasePipe, SlicePipe } from '@angular/common';
import { RestrictInputDirective } from '@bs24/core/directives/restrict-input.directive';
import { SvgIconDirective } from '@bs24/core/directives/svg-icon.directive';

@Component({
  selector: 'mobile-prefix',
  styleUrl: './mobile-prefix.component.scss',
  templateUrl: './mobile-prefix.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MobilePrefixComponent),
      multi: true
    }
  ],
  imports: [
    LowerCasePipe,
    SlicePipe,
    ReactiveFormsModule,
    FormsModule,
    RestrictInputDirective,
    SvgIconDirective
],
  standalone: true
})
export class MobilePrefixComponent extends BaseMobilePrefix {
}
