import { IPrefix } from './prefix';

export class IMobilePrefix {
  phone: string;
  prefix: IPrefix;

  constructor(phone: string, prefix: IPrefix) {
    this.phone = phone;
    this.prefix = prefix;
  }
}
