@if (error) {
  @switch (server) {
    @case (true) {
      <span class="alert alert-danger d-flex align-items-center">{{ error.value }}</span>
    }
    @default {
      <div class="error">{{ ('error-' + error.key) | translate: {value: error.value} }}</div>
    }
  }
}
