import { AfterViewInit, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';

import { NgbDate, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { NgStyle } from '@angular/common';
import { SvgIconDirective } from '@bs24/core/directives/svg-icon.directive';
import { Ages } from '@bs24/core/models/ages';
import { asapScheduler } from 'rxjs';

/**
 * The component contains from, to date input fields
 */
@Component({
  selector: 'date-range',
  templateUrl: './date-range.component.html',
  styleUrls: ['./date-range.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateRangeComponent),
      multi: true
    }
  ],
  standalone: true,
  imports: [
    NgbInputDatepicker,
    FormsModule,
    NgStyle,
    SvgIconDirective
  ]
})
export class DateRangeComponent implements ControlValueAccessor, AfterViewInit {
  /**
   * outputs the blur effect flow from the child to the parent
   */
  @Output()
  blur = new EventEmitter<any>(null);
  /**
   * the minimum allowed date of the date range
   */
  @Input()
  minDate: Date;
  /**
   * the maximum allowed date of the date range
   */
  @Input()
  maxDate: Date;
  /**
   * responsible for disabling the field
   */
  isDisabled: boolean;
  /**
   * fromDate the value that is set by from input field
   */
  fromDate: string;
  /**
   * toDate the value that is set by the toDate input field
   */
  toDate: string;

  /**
   * The constructor, we set a default value for the fromDate and toDate variables
   */
  constructor() {
    this.fromDate = Ages.lastWeek.toISOString();
    this.toDate = new Date(new Date().setHours(23, 59, 59, 0)).toISOString();
  }

  /**
   * A lifecycle hook that is called after Angular has fully initialized a component's view, and updates from, and to values
   */
  ngAfterViewInit() {
    asapScheduler.schedule(() => {
      this.update({ from: this.fromDate, to: this.toDate });
    });
  }

  /**
   * we save the given function from registerOnChange, so our class calls is at the appropriate time.
   * @param _model
   * @private
   */
  propagateChange(_model: any) {
  }

  /**
   * we save the given function of registerOnTouched, so that our class calls it when the control should be considered blurred or "touched".
   */
  onTouched() {
  }

  /**
   * updates fromDate and toDate values, when programmatic changes from model to view are requested
   * @param model
   */
  writeValue(model: { from: string, to: string } | undefined) {
    if (model) {
      [this.fromDate, this.toDate] = [model.from, model.to];
    } else {
      [this.fromDate, this.toDate] = ['', ''];
    }
  }

  /**
   * Registers a callback function that is called when the control's value changes in the UI
   * @param fn
   */
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  /**
   * Registers a callback function that is called by the forms API on initialization to update the form model on blur
   * @param fn
   */
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  /**
   * function that is called by the forms API when the control status changes to or from 'DISABLED'.
   * @param isDisabled
   */
  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }

  /**
   * updates the model when one of the values been updated
   * @param _model
   */
  update(_model: { from: string, to: string }) {
    if (_model.from && _model.to) {
      this.propagateChange(_model);
    }
  }

  /**
   * emits the blur event to the parent element, and it invokes registerOnTouched method
   * @param event
   */
  onBlur(event: Event) {
    // todo: trick for propagate blur, check if there is another way
    this.onTouched();
    this.blur.emit(event);
  }

  /**
   * turns a date into a NgbDate date
   * @param date
   */
  toNbgDate(date: Date | string): NgbDate {
    const d = new Date(date);
    return new NgbDate(d.getFullYear(), d.getMonth() + 1, d.getDate());
  }
}
